:root[data-theme='dark'] {
  --c-body-bg: #1E1E1E;
  --c-light: #1E1E1E;
  --c-light-1: #1E1E1E;
  --c-light-2: #1E1E1E;
  --c-light-3: #4A4A4A;
  --c-light-4: rgba(30, 30, 30, 0.15);
  --c-light-5: #7B8EBC;
  --c-dark: #1E1E1E;
  --c-orange: #FF6600;
  --c-orange-2: #FFDDB0;
  --c-orange-3: #7B8EBC;
  --c-red-1: #FF270D;
  --c-red-2: #EA5455;
  --c-red-3: #D00909;
  --c-red-4: #FF3113;
  --c-bg-red: #FFE3E3;
  --c-yellow: #FDB515;
  --c-blue: #4D8CD7;
  --c-bg-blue: #4A4A4A;
  --c-green: #36A952;
  --c-bg-green: #E5EDC3;
  --c-grey-1: #FFF;
  --c-gray-2: #4A4A4A;
  --c-grey-3: #E4E4E4;
  --c-grey-4: #555555;
  --c-grey-5: #D1D1D1;
  --c-grey-6: #D8D7D6;
  --c-dropdown-bg: #737373;
  --c-sucess: #36A952;
  --c-error: #DB3232;
  --switch-bg: #4AD395;
  --c-text-light: #FFF;
  --c-text-dark: #FCFCFCDE;
  --c-btn-primary-color: #F57824;
  --c-bg-draft: #FFF8EF;
  --c-bg-expired: rgba(228, 228, 228, 0.60);
  --c-bg-ongoing: #E3FFF2;
  --c-bg-accordion-btn: #1E1E1E;
  --c-border: #7B8EBC;
  --c-modal-header-bg: #2E2E2E;
  --c-modal-body-bg: #151515;
  --c-modal-footer-bg: #151515;
  --c-btn-secondary-border: rgba(255, 255, 255, 0.75);
  --c-textarea-bg: #1E1E1E;
  --c-textarea-border: #1E1E1E;
  --c-model-content-border: #1E1E1E;
  --c-text-color: rgba(255, 255, 255, 0.75);
  --c-close-icon: rgba(255, 255, 255, 0.75);
  --c-ngoption: #696868;
  --c-selected: #2E2E2E;
  --c-selectedFolder: #3E3E3E;
  --c-pagetitle: rgba(252, 252, 252, 0.87);
  --c-table-header: #2E2E2E;
  --c-table-body: #151515;
  --c-status-unanswered: rgba(115, 115, 115, 0.60);
  --c-status-review: #324760;
  --c-status-answered: #37624E;
  --c-status-unanswered-text: rgba(255, 255, 255, 0.75);
  --c-status-review-text: rgba(255, 255, 255, 0.75);
  --c-status-answered-text: rgba(255, 255, 255, 0.75);
  --c-text-color-header: #3A3932;
}