:root[data-theme='light'] {
  --c-body-bg: #FFF;
  --c-light: #FFF;
  --c-light-1: #F7F8F9;
  --c-light-2: #E4E4E4;
  --c-light-3: #EFF4EF;
  --c-light-4: #EBEEF6;
  --c-light-5: #EBEEF6;
  --c-dark: #3A3932;
  --c-orange: #FF6600;
  --c-orange-2: #FFDDB0;
  --c-orange-3: #FFDDB0;
  --c-red-1: #FF270D;
  --c-red-2: #EA5455;
  --c-red-3: #D00909;
  --c-red-4: #FF3113;
  --c-bg-red: #FFE3E3;
  --c-yellow: #FDB515;
  --c-blue: #3C5A9A;
  --c-bg-blue: #CEDCEC;
  --c-green: #36A952;
  --c-bg-green: #E5EDC3;
  --c-grey-1: #696868;
  --c-gray-2: #AAAAAA;
  --c-grey-3: #E4E4E4;
  --c-grey-4: #555555;
  --c-grey-5: #D1D1D1;
  --c-grey-6: #D8D7D6;
  --c-grey-7: rgba(228, 228, 228, 0.60);
  --c-grey-8: #3A3932;
  --c-dropdown-bg: rgba(228, 228, 228, 0.60);
  --c-sucess: #36A952;
  --c-error: #DB3232;
  --switch-bg: #4AD395;
  --c-text-light: #FFF;
  --c-text-dark: #3A3932;
  --c-btn-primary-color: #FF6600;
  --c-header-box-shadow: rgba(194, 194, 194, 0.20);
  --c-bg-draft: #FFF8EF;
  --c-bg-expired: rgba(228, 228, 228, 0.60);
  --c-bg-ongoing: #E3FFF2;
  --c-bg-accordion-btn: #FFDDB0;
  --c-border: #CEDCEC;
  --c-modal-header-bg: #FFDDB0;
  --c-modal-body-bg: #FFFFFF;
  --c-modal-footer-bg: #FFFFFF;
  --c-btn-secondary-border: #696868;
  --c-textarea-bg: #FFFFFF;
  --c-textarea-border: #CEDCEC;
  --c-model-content-border: #FFFFFF;
  --c-text-color: #3A3932;
  --c-close-icon: #3A3932;
  --c-ngoption: #696868;
  --c-selected: rgba(228, 228, 228, 0.60);
  --c-selectedFolder: rgba(228, 228, 228, 0.80);
  --c-pagetitle: #3A3932;
  --c-table-header: rgba(228, 228, 228, 0.60);
  --c-table-body: #FFFFFF;
  --c-status-unanswered: #F7F8F9;
  --c-status-review: #CEDCEC;
  --c-status-answered: #E3FFF2;
  --c-status-unanswered-text: #AAAAAA;
  --c-status-review-text: #3C5A9A;
  --c-status-answered-text: #4AD395;
  --c-progress-success: #00B144;
  --c-progress-grey: #D8D7D6;
  --c-date-time-picker-bs: #89898927;
  --c-text-color-header: #3A3932;
}