/* checkbox */

.form-check.form-checkbox-orange {
  .form-check-input {
    border-color: var(--c-gray-2);

    &:checked {
      color: var(--c-light);
      border-color: var(--c-orange);
      background-color: var(--c-orange);

      &:focus {
        border-color: var(--c-orange);
      }
    }

    &:focus {
      border-color: var(--c-gray-2);
      box-shadow: none;
    }
  }
}

/* switch */

.form-check.form-switch.form-switch-green {
  .form-check-input {
    background-color: var(--c-gray-2) !important;
    background-image: url('../assets/images/switch-circle.svg') !important;
    border: none;

    &:checked {
      background-color: var(--switch-bg) !important;
      border-color: var(--switch-bg) !important;
    }

    &:focus {
      background-image: url('../assets/images/switch-circle.svg') !important;
      border: none;
      box-shadow: none;
    }
  }
}


/* form group */

.form-group {
  margin-bottom: 2.2222222222222vh;

  .form-label {
    color: var(--c-dark);
    font-size: var(--fs-16);
    font-family: "Proxima Nova W05 Bold";
    line-height: var(--lh-normal);
    margin-bottom: 1.4814814814815vh;
  }

  .form-control {
    height: 4.4444444444444vh;
    min-height: 40px;
    max-height: 48px;
    padding: 0.92592592592593vh 0.521vw;
    border-radius: 4px;
    border: 1px solid var(--c-bg-blue);
    color: var(--c-dark);
    font-size: var(--fs-16);
    font-family: "Proxima Nova W05 Regular";
    line-height: normal;

    &:focus {
      box-shadow: none;
    }
  }
}


.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: var(--c-light-2);
  opacity: 0.6;
}


/*search box */

.search-field {
  width: 20.938vw;
  max-width: 402px;
  max-height: 46px;
  min-height: 40px;

  input {
    border-right-width: 0px;
    border-color: var(--c-bg-blue);

    &:focus {
      border-right-width: 0px;
      border-color: var(--c-bg-blue);
      box-shadow: none;
    }

  }

  .search-btn {
    display: flex;
    align-items: center;
    max-width: 52px;
    background-color: var(--c-orange);

    &:active {
      background-color: var(--c-orange);
      border-color: var(--c-orange);
    }
  }
}

/* placeholder */

.input-group,
.form-group {
  ::-webkit-input-placeholder {
    @extend .body-sub-text;
    color: var(--c-gray-2);
  }

  :-ms-input-placeholder {
    color: var(--c-gray-2);
    font-size: var(--fs-16);
    font-style: normal;
    font-family: "Proxima Nova W05 Regular";
    line-height: var(--lh-normal);
  }

  ::placeholder {
    @extend .body-sub-text;
    color: var(--c-gray-2);
  }
}


/* input type number  */
.input-no-sm {
  display: flex;
  width: 4.167vw;
  max-width: 80px;
  height: 4.4444444444444vh;
  max-height: 48px;
  min-height: 36px;
  padding: 0.92592592592593vh 0.521vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  ;
  color: var(--c-gray-1);
  -moz-appearance: textfield;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: 1px solid var(--c-bg-blue);
    box-shadow: none;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


}


/* custom radio button css */

.custom-radio {
  font-size: var(--fs-16);
  color: var(--c-text-color);
  display: flex;
  align-items: center;
  gap: 0.5em;

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--c-light);
    margin: 0;
    color: var(--c-orange);
    max-width: 24px;
    max-height: 24px;
    width: 1.25vw;
    height: 1.25vw;
    border: 1px solid var(--c-bg-blue);
    border-radius: 50%;
    display: grid;
    place-content: center;

    &::before {
      content: "";
      height: 0.833vw;
      width: 0.833vw;
      max-width: 16px;
      max-height: 16px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--c-orange);
    }

    &:focus {
      box-shadow: none;
    }
  }

  input[type="radio"]:checked {
    border: 1px solid var(--c-orange);

    &::before {
      transform: scale(1);
    }
  }
}

.custom-radio+.custom-radio {
  margin-top: 1em;
}

/* custom radio button css */


// file upload 
.uploading-sql-file-draggable {
  min-width: 641px;
  height: 241px;
  min-height: 241px !important;
}

.file-upload-wrapper {
  position: relative;

  svg-icon {
    width: 18px;
    height: 20px;
    display: inline-block;

    svg {
      max-width: 100%;
    }
  }

  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.file-uploadng-wrapper {
  svg-icon {
    width: 18px;
    height: 20px;
    display: inline-block;

    svg {
      max-width: 100%;
    }
  }

  span {
    color: var(--c-gray-2) !important;
  }
}

.uploading-sql-file-modal {
  width: 641px;
  height: 241px;
  gap: 0px;
  opacity: 0px;
  border-radius: 8px;
  border: 1px solid var(--c-status-review);

  .uploading-header {
    border-bottom: 1px solid var(--c-status-review);
  }

  .uploading-body {
    padding: 48px 74px;

    .col-md-4 {
      flex-basis: 150px;
    }

    .col-md-8 {
      flex: 1;
    }

    .progress {
      width: 276px;
      position: relative;
      overflow: visible;
      height: 8px;
      background-color: var(--c-progress-grey) !important;

      .progress-bar {
        span {
          position: absolute;
          right: -26px;
          color: var(--c-grey-4);
          font-size: var(--fs-12);
        }
      }

      .bg-success {
        background-color: var(--c-progress-success) !important;
        border-radius: 100px !important;
      }
    }

    .delete-icon-wrapper {
      margin-left: 32px;
    }
  }
}