.btn-primary {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W05 Bold";
  --bs-btn-font-family: var(--app-font-family-bold);
  --bs-btn-color: var(--c-text-light);
  --bs-btn-bg: var(--c-btn-primary-color);
  --bs-btn-border-color: var(--c-btn-primary-color);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-text-light);
  --bs-btn-hover-bg: var(--c-red-1);
  --bs-btn-hover-border-color: var(--c-btn-primary-color);
  --bs-btn-active-color: var(--c-text-light);
  --bs-btn-active-bg: var(--c-btn-primary-color);
  --bs-btn-active-border-color: var(--c-btn-primary-color);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.btn-primary-md {
  width: 6.979vw;
  min-width: max-content;
  max-width: 134px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.250vw;
  font-size: var(--fs-16);
  @extend .btn-primary
}

.btn-secondary-orange {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  height: 5.5555555555556vh;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W05 Bold";
  --bs-btn-color: var(--c-orange);
  --bs-btn-bg: var(--c-light);
  --bs-btn-border-color: var(--c-orange);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-text-light);
  --bs-btn-hover-bg: var(--c-red-1);
  --bs-btn-hover-border-color: var(--c-orange);
  --bs-btn-active-color: var(--c-light);
  --bs-btn-active-bg: var(--c-btn-primary-color);
  --bs-btn-active-border-color: var(--c-orange);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.btn-secondary-orange-md {
  min-width: max-content;
  width: 8.125vw;
  max-width: 156px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.250vw;
  font-size: var(--fs-16);
  white-space: nowrap;
  @extend .btn-secondary-orange
}

.btn-secondary-grey {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  height: 5.5555555555556vh;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W01 SemiBold";
  --bs-btn-color: var(--c-grey-1);
  --bs-btn-bg: var(--c-light);
  --bs-btn-border-color: var(--c-grey-1);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-grey-1);
  --bs-btn-hover-bg: var(--c-light-1);
  --bs-btn-hover-border-color: var(--c-grey-1);
  --bs-btn-active-color: var(--c-grey-1);
  --bs-btn-active-bg: var(--c-light-1);
  --bs-btn-active-border-color: var(--c-grey-1);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}


.btn-secondary-grey-md {
  width: 8.125vw;
  max-width: 156px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.250vw;
  font-size: var(--fs-16);
  @extend .btn-secondary-grey
}

.btn-warning {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W01 SemiBold";
  --bs-btn-color: var(--c-light);
  --bs-btn-bg: var(--c-red-1);
  --bs-btn-border-color: var(--c-red-1);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-light);
  --bs-btn-hover-bg: var(--c-red-3);
  --bs-btn-hover-border-color: var(--c-red-3);
  --bs-btn-active-color: var(--c-light);
  --bs-btn-active-bg: var(--c-red-3);
  --bs-btn-active-border-color: var(--c-red-3);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}


.flat-btn {
  display: inline-flex;
  min-height: 28px;
  max-height: 32px;
  height: 2.962962962963vh;
  padding: 0 0.625vw;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--c-light-1);
  background: var(--c-light);
  box-shadow: -4px 5px 20px 0px var(--c-light-4);
  color: var(--c-text-dark);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
  cursor: pointer;
}