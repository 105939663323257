/* margin */
.m-16 {
  margin: 2.2222222222vh 1.25vw;
}

.mx-16 {
  margin-right: 0.833vw;
  margin-left: 0.833vw;
}

.my-16 {
  margin-top: 1.4814814814815vh;
  margin-bottom: 1.4814814814815vh;
}

.mt-16 {
  margin-top: 1.4814814814815vh;
}

.mr-16 {
  margin-right: 0.833vw;
}

.mb-16 {
  margin-bottom: 1.4814814814815vh;
}

.ml-16 {
  margin-left: 0.833vw;
}

.m-24 {
  margin: 2.2222222222vh 1.25vw;
}

.mx-24 {
  margin-right: 1.250vw;
  margin-left: 1.250vw;
}

.my-24 {
  margin-top: 2.2222222222vh;
  margin-bottom: 2.2222222222vh;
}

.mt-24 {
  margin-top: 2.2222222222222vh;
}

.mr-24 {
  margin-right: 1.250vw;
}

.mb-24 {
  margin-bottom: 2.2222222222222vh;
}

.ml-24 {
  margin-left: 1.250vw !important;
}


.m-32 {
  margin: 2.962962962963vh 1.667vw;
}

.mx-32 {
  margin-right: 1.667vw;
  margin-left: 1.667vw;
}

.my-32 {
  margin-top: 2.962962962963vh;
  margin-bottom: 2.962962962963vh;
}

.mt-32 {
  margin-top: 2.962962962963vh;
}

.mr-32 {
  margin-right: 1.667vw;
}

.mb-32 {
  margin-bottom: 2.962962962963vh;
}

.ml-32 {
  margin-left: 1.667vw;
}

.mr-48 {
  margin-right: 2.500vw;
}

.ml-48 {
  margin-left: 2.500vw;
}

// padding
.p-16 {
  padding: 1.4814814814815vh 0.833vw;
}

.px-16 {
  padding-right: 0.833vw;
  padding-left: 0.833vw;
}

.py-16 {
  padding-top: 1.4814814814815vh;
  padding-bottom: 1.4814814814815vh;
}

.pt-16 {
  padding-top: 1.4814814814815vh;
}

.pr-16 {
  padding-right: 0.833vw;
}

.pb-16 {
  padding-bottom: 1.4814814814815vh;
}

.pl-16 {
  padding-left: 0.833vw;
}

.p-24 {
  padding: 2.2222222222vh 1.25vw;
}

.px-24 {
  padding-right: 1.250vw;
  padding-left: 1.250vw;
}

.py-24 {
  padding-top: 2.2222222222222vh;
  padding-bottom: 2.2222222222222vh;
}

.pt-24 {
  padding-top: 2.2222222222222vh;
}

.pr-24 {
  padding-right: 1.250vw;
}

.pb-24 {
  padding-bottom: 2.2222222222222vh;
}

.pl-24 {
  padding-left: 1.250vw;
}

.pt-32 {
  padding-top: 2.962962962963vh;
}

.pr-32 {
  padding-right: 1.667vw;
}

.pb-32 {
  padding-bottom: 2.962962962963vh;
}

.pl-32 {
  padding-left: 1.667vw;
}

.py-32 {
  padding-top: 2.962962962963vh;
  padding-bottom: 2.962962962963vh;
}

.py-48 {
  padding-top: 4.4444444444444vh;
  padding-bottom: 4.4444444444444vh;
}

/* font */

.ff-bold {
  font-family: var(--ff-bold);
}

.ff-semibold {
  font-family: var(--ff-semibold);
}

.fs-12 {
  font-size: var(--fs-12);
}

.fs-14 {
  font-size: var(--fs-14);
}

.fs-16 {
  font-size: var(--fs-16);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-22 {
  font-size: var(--fs-22);
}

.fs-24 {
  font-size: var(--fs-24);
}

.fs-28 {
  font-size: var(--fs-28);
}

.fs-30 {
  font-size: var(--fs-30);
}

.fs-32 {
  font-size: var(--fs-32);
}

.fs-40 {
  font-size: var(--fs-40);
}

/* font */

.lh-16 {
  line-height: var(--lh-16);
}

.cursor-pointer {
  cursor: pointer;
}

/* typography */

.page-title {
  position: relative;
  width: max-content;
  font-size: var(--fs-24);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
  color: var(--c-pagetitle);
  padding-bottom: 4px;
  margin-bottom: 2.2222222222222vh;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--c-orange);
    border-radius: 2px;
  }
}

.primary-text {
  color: var(--c-orange);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: 150%;
  padding: 2px 8px;

  &:hover {
    border-radius: 100px;
    background-color: var(--c-light-1);
  }
}

.heading-1 {
  color: var(--c-text-dark);
  font-size: var(--fs-24);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}

.heading-2 {
  color: var(--c-text-dark);
  font-size: var(--fs-20);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}

.heading-3 {
  color: var(--c-text-dark);
  font-size: var(--fs-18);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-24);
}

.big-body-text {
  color: var(--c-text-dark);
  font-size: var(--fs-18);
  line-height: normal;
}

.big-body-text-2 {
  color: var(--c-grey-1);
  font-size: var(--fs-18);
  line-height: normal;
}

.big-body-text-3 {
  color: var(--c-gray-2);
  font-size: var(--fs-18);
  line-height: normal;
}

.body-sub-text {
  color: var(--c-grey-1);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
}

.body-pill-text {
  color: var(--c-text-dark);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-normal);
}

.big-bold-assistive-text {
  color: var(--c-text-dark);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-bold-assistive-text {
  color: var(--c-grey-1);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-bold-assistive-text-orange {
  color: var(--c-orange);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-text {
  color: var(--c-grey-1);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-24z);
  word-break: break-word;
}

.text-button {
  color: var(--c-blue);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-24z);
  cursor: pointer;
}

.small-assistive-text {
  color: var(--c-grey-1);
  font-size: var(--fs-12);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
}

/* typography */

/* level */

@mixin theme($theme: var(--c-bg-green)) {
  background: $theme;
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
}

.level-easy {
  @include theme;
  color: var(--c-green);
}

.level-medium {
  @include theme($theme: var(--c-bg-blue));
  color: var(--c-blue);
}

.level-difficult {
  @include theme($theme: var(--c-bg-red));
  color: var(--c-red-2);
}


/* status */

@mixin status($status: var(--c-bg-draft)) {
  background: $status;
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
}

.status-draft {
  @include status;
  color: var(--c-yellow);
}

.status-yet-to-start {
  @include status($status: var(--c-bg-blue));
  color: var(--c-blue);
}

.status-expired {
  @include status($status: var(--c-bg-expired));
  color: var(--c-grey-1);
}

.status-ongoing {
  @include status($status: var(--c-bg-ongoing));
  color: var(--c-sucess);
}

/* dropdown menu */

.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  background: var(--c-light);
  padding: 0px;

  li {
    width: 100%;

    a {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0.92592592592593vh 0.833vw;
      @extend .body-sub-text;

      img {
        margin-right: 8px;
      }

      &:hover,
      &:active {
        background-color: var(--c-dropdown-bg);
      }
    }
  }
}


.dropdown-filter-toggle {
  &::after {
    width: 10px;
    height: 10px;
    margin-top: 3px;
    background-image: url("../assets/images/dropdown-open.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
  }

  &:hover {
    &::after {
      transform: rotate(180deg);
    }
  }
}

/* nav tabs */
.nav-tabs.create-project-tabs {
  border-bottom: 1px solid var(--c-bg-blue);

  li {
    .nav-link {
      position: relative;
      color: var(--c-grey-1);
      font-size: var(--fs-16);
      font-family: "Proxima Nova W05 Regular";
      line-height: var(--lh-normal);
      border: none;
      padding: 0px 0px 3px 0px;
      margin-right: 2.500vw;

      &:hover {
        border: none;

      }
    }

    .nav-link.active {
      color: var(--c-dark);
      font-size: var(--fs-16);
      font-style: normal;
      font-family: "Proxima Nova W05 Bold";
      line-height: var(--lh-normal);
      border: none;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: -webkit-fill-available;
        height: 2px;
        background-color: var(--c-orange);
        border-radius: 2px;
      }
    }
  }
}

/* ng select */

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  display: none;
}

ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--c-gray-2) !important;
  font-size: var(--fs-16);
}

ng-select .ng-select-container .ng-value-container .ng-value {
  color: var(--c-text-dark) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label {
  background-color: var(--c-body-bg);
  font-size: var(--fs-14);
  line-height: 24px;
  font-family: "Proxima Nova W05 Regular";
}

.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      flex-wrap: nowrap !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ng-select .ng-select-container {
  height: 4.4444444444vh !important;
  min-height: 40px;
  max-height: 48px;
  border-radius: 8px;
  border-color: var(--c-bg-blue) !important;
  background: var(--c-body-bg) !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: none !important;
  }
}

.ng-dropdown-panel {
  border-color: var(--c-bg-blue) !important;
}

.ng-dropdown-panel.ng-select-bottom {
  border-top-color: var(--c-bg-blue) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background: var(--c-body-bg) !important;
  color: var(--c-grey-1) !important;
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;

  &:hover {
    color: var(--c-grey-1) !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: var(--c-body-bg) !important;
  // color: var(--c-dark) !important;
}

//this is for single select
.multi-ng-select .ng-value .ng-value-label::after {
  content: ",";
  font-weight: bolder;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-family: "Proxima Nova W05 Regular";
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: initial !important;
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container div:nth-last-child(2) span.ng-value-label:after {
  display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--c-selected) !important;
  font-family: "Proxima Nova W05 Regular";
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: var(--c-selected) !important;
  color: rgba(85, 85, 85, 0.75);
  font-size: var(--fs-14);
  font-style: normal;
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  color: var(--c-grey-1) !important;
  font-size: var(--fs-14);
  font-style: normal;
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}

.ng-dropdown-panel.ng-select-multiple.ng-select-top {

  .ng-option,
  .ng-option.ng-option-selected {
    color: var(--c-grey-1) !important;
    font-size: var(--fs-14);
    font-style: normal;
    font-family: "Proxima Nova W05 Regular";
    line-height: normal;
  }
}

.custom-clear-all ng-select ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  &:nth-child(1) {
    display: flex !important;
    justify-content: end;
    color: var(--c-orange) !important;
  }
}

.custom-ng-select .ng-select-container {
  min-height: 62px;
  min-width: 200px;
}


.ng-arrow-wrapper {
  background-image: url("../assets/images/dropdown-open.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  .ng-arrow {
    border: none !important;
  }
}

.ng-select-opened {
  .ng-arrow-wrapper {
    transform: rotate(180deg);
  }
}

/* sort ng select */
.sort-ng-select {
  min-width: 72px;
  max-height: 36px;

  .ng-select-container {
    cursor: pointer;
    border: none;

    .ng-clear-wrapper {
      .ng-clear {
        margin-left: 4px;
      }
    }

    .ng-value-container {
      padding-left: 14px;
    }

    &:hover {
      box-shadow: none;
      background-color: var(--c-light-1) !important;
      border-radius: 0px !important;

      .ng-value-container {
        .ng-placeholder {
          color: var(--c-orange) !important;
        }

        .ng-value {
          .ng-value-label {
            background: var(--c-light-1);
          }
        }

      }

    }

  }

  .ng-has-value {
    &::before {
      content: "";
      position: absolute;
      left: 8px;
      top: 10px;
      width: 6px;
      height: 6px;
      background: url("../assets/images/red_Dot.svg");
      background-repeat: no-repeat;
      background-size: 100%;
    }



  }

  .ng-dropdown-panel {
    border: 1px solid var(--c-bg-blue);
    box-shadow: none;
    border-radius: 4px;
    width: 272px;

    .ng-dropdown-panel-items {
      border-radius: 4px;

      .ng-option {
        color: var(--c-grey-1);
        font-size: var(--fs-14) !important;
        line-height: normal !important;
        font-family: "Proxima Nova W05 Regular";

        .ng-option-label {
          color: var(--c-grey-1);
          font-size: var(--fs-14) !important;
          line-height: normal !important;
          font-family: "Proxima Nova W05 Regular";
        }
      }
    }

  }

}

/* sort ng select */


/* Ck editor*/
.ck.ck-content {
  padding: 0 1.250vw !important;

  p {
    word-break: break-word;
  }
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  background-color: var(--c-light-1);

}


.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--c-bg-blue) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;

}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-color: var(--c-bg-blue) !important;
  background-color: var(--c-light-1);
  height: 53.68px !important;
}

/*required*/
.required::after {
  content: "*";
  color: var(--c-red-1);
  padding-left: 4px;
}

.error-message {
  color: var(--c-red-1);
}

/*Footerbtn*/
.footerBtn-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: var(--footer-height);
  padding: 12px 1.667vw 12px 0px;
  align-items: center;
  box-shadow: 0px -4px 6px 0px rgba(58, 57, 50, 0.05);
}

/*Footerbtn*/
.footerBtn {
  position: fixed;
  bottom: 0px;
  width: calc(100vw - var(--side-bar-width));
  height: var(--footer-height);
  background-color: var(--c-light);
  padding: 12px 1.667vw 12px 0px;
  align-items: center;
  right: 0px;
  box-shadow: 0px -4px 6px 0px rgba(58, 57, 50, 0.05);
}

content container .content-container {
  position: relative;
  height: calc(100vh - 72px);
  overflow: hidden;
}

/* Modals */
.test-case-modal {
  .modal-dialog {
    height: 82.962962962963vh;
    max-width: 72.917vw;
  }
}


.image-zoom-modal {
  .modal-dialog {
    width: 52.917vw;
    max-width: 1016px;
    overflow: hidden;
  }
}

.modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--c-orange-2);

  .close-button {
    border-style: none;
    background-color: var(--c-orange-2);
    max-width: 20px;
    width: 1.042vw;
    max-height: 20px;
    height: 1.8518518518519vh;

    svg-icon {
      max-width: 100%;
      max-height: 100%;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .close-icon {
    width: 0.938vw;
    height: 0.938vw;
    max-width: 18px;
    max-height: 18px;

    svg-icon {
      svg {
        max-width: 100%;
      }
    }
  }
}

/*accodrion */
.accordion {
  .accordion-item {
    border-color: var(--c-bg-blue);
    background-color: var(--c-light);

    .accordian-header {
      .accordion-button {
        padding: 12px;
      }
    }

    .accordian-body {
      padding: 16px;
    }

  }
}

.project-player-accordian-button {
  &::after {
    display: none;
  }
}

.accordion-button {
  background-color: var(--c-light-1);
  cursor: default !important;
  color: var(--c-text-dark) !important;



  &:hover {
    cursor: default;
  }

  .accordion-toggle-icon {
    display: block;
    max-width: 16px;
    min-width: 12px;
    width: 0.833vw;
    max-height: 16px;
    min-height: 12px;
    height: 0.833vw;
  }
}


.accordion-button:not(.collapsed) {
  background-color: var(--c-light-1);
  box-shadow: none;
}

.accordion-button .accordion-toggle-icon:not(.collapsed)::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/projectPlayer/collapse_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: inherit;
  max-width: 16px;
  min-width: 12px;
  width: 0.833vw;
  max-height: 16px;
  min-height: 12px;
  height: 0.833vw;
}

.accordion-button .accordion-toggle-icon.collapsed::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/projectPlayer/expand_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: 100%;
  max-width: 16px;
  min-width: 12px;
  width: 0.833vw;
  max-height: 16px;
  min-height: 12px;
  height: 0.833vw;
}

.accordion:focus,
.accordion-button :focus,
.accordion-button:focus,
.accordion-button :focus {
  outline: none !important;
  box-shadow: none !important;
}

.inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
}


.theme-dropdown,
.output-dropdown {
  span {
    &::after {
      border: none;
    }
  }

}

.pulse {
  overflow: hidden;
  background: rgba(228, 228, 228, 0.60);
}

.pulse:before {
  content: "";
  display: inline-flex;
  height: 100%;
  width: 100%;
  animation: pulse 1s infinite;
  background: linear-gradient(to right, transparent, #D9D9D9, transparent);
  animation-direction: alternate;
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/*date time picker */


/* date time picker */

/* filter dropdown */
.filter-dropdown {

  .dropdown-filter-toggle {
    position: relative;
    min-width: 72px;
    height: 36px;
    text-align: center;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c-grey-1);
    cursor: pointer;
    font-size: var(--fs-16);

    &:hover {
      background-color: var(--c-light-1);
      color: var(--c-orange) !important;
    }

    span {
      position: absolute;
      left: 4px;
      top: 2px;
    }
  }

  .first-level-menu {
    right: 0;
  }

  .dropdown-menu {
    width: 272px;
    border-radius: 4px;
    border: 1px solid var(--c-bg-blue);
    background: var(--c-light);
    padding: 0;

    li {
      .dropdown-toggle {
        &::after {
          border: none;
          background-image: url("../assets/images/dropdown-left-open.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 13px;
          left: 0;
          margin-top: 0px !important;
          margin-left: 8px !important;
        }

        &:hover {
          &::after {
            transform: rotate(0deg);
          }
        }
      }

      a {
        display: block !important;
        padding: 10px 24px;
        cursor: pointer;

        &:active {
          color: var(--c-grey-1);
          background: rgba(228, 228, 228, 0.60);
        }

      }
    }
  }

  li:hover>ul.dropdown-menu {
    display: block;
  }

  .dropdown-submenu {
    position: relative;

    ul {
      li {
        a {
          padding: 0px !important;

          &:hover {
            background-color: transparent;
          }

          .filter-select {

            &:hover {
              cursor: pointer;
              background: rgba(228, 228, 228, 0.60);
            }

            .form-check-label {
              cursor: pointer;
            }
          }

        }
      }
    }
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    right: 100%;
  }
}

.filter-select {
  position: relative;
  height: 36px;
  min-height: auto;
  padding: 10px 16px 10px 8px;
  margin-bottom: 0px;

  .form-check-input {
    border: none;
    background-color: transparent;
    margin: 2px 8px 0px 0px;

    &:checked[type=checkbox] {
      background-image: url("../assets/images/tick.svg");
      background-color: transparent;
      border: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-check-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    width: 100%;
    padding: 10px 16px 0px 28px;
  }
}

.no-padding {
  padding: 0px !important;
}


// total projects selected

.total-edit-marks-card {
  background: var(--c-bg-draft);
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 4px;


  span {
    font-size: var(--fs-16);
    line-height: var(--lh-24);

    &:first-child {
      font-family: "Proxima Nova W05 Bold";
      color: var(--c-dark);
    }

    &:last-child {
      font-family: "Proxima Nova W01 SemiBold";
      color: var(--c-orange);
    }
  }
}

.projects-selected {
  @include status;
  color: var(--c-orange);
}

// pagination 
.pagination {
  .previous-btn {
    color: rgba(85, 85, 85, 0.60);
    font-size: var(--fs-16);
    font-style: normal;
    font-family: "Proxima Nova W01 SemiBold";
    line-height: normal;
    cursor: pointer;
  }

  .current-btn {
    color: var(--c-text-dark);
    text-align: center;
    font-size: var(--fs-16);
    font-style: normal;
    font-family: "Proxima Nova W05 Bold";
    line-height: normal;

    span {
      font-family: "Proxima Nova W05 Regular";
    }
  }

  .next-btn {
    color: var(--c-orange);
    font-size: var(--fs-16);
    font-family: "Proxima Nova W01 SemiBold";
    line-height: normal;
    cursor: pointer;
  }

  .btn-disabled {
    color: rgba(85, 85, 85, 0.60);
    font-size: var(--fs-16);
    font-family: "Proxima Nova W01 SemiBold";
    line-height: normal;
    cursor: none;
  }
}

.new-practice-project-setting-modal {
  .modal-dialog {
    width: 52.917vw;
    max-width: 1016px;
  }
}


section {
  .body-text {
    p {
      word-wrap: break-word !important;
      word-break: break-word !important;
    }
  }
}

.action-container {
  ng-select .ng-select-container {
    min-height: 36px !important;
    max-height: 36px !important;

    .ng-value-container .ng-placeholder {
      color: var(--c-ngoption) !important;
    }

    .ng-arrow-wrapper {
      margin-top: 4px !important;
    }
  }
}

figure {
  max-width: 300px;
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
  }
}

.clear-button {
  cursor: pointer;
  position: absolute;
  display: block;
  right: 50px;
  top: 9px;
  width: 13px;
  height: 12px;
  z-index: 9;

  svg-icon {
    max-width: 100%;
    max-height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

}

.inputField {
  padding-right: 24px;
}


.multi-ng-select {
  .ng-select {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {

        .ng-optgroup,
        .ng-option-selected {
          background-color: var(--c-light) !important;
        }

        .ng-option {
          &:hover {
            background-color: var(--c-selected) !important;
          }
        }

        .ng-option-child {
          padding-left: 12px !important;
        }
      }
    }
  }

  .custom-checkbox {
    appearance: none;

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      margin-right: 4px;
    }

    &:checked {
      &::after {
        content: "";
        background: url('../assets/images/ngdropdown-selected.svg');
        width: 10px;
        height: 8px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        color: var(--c-orange);
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 1056 !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}


figure.image.on {
  position: relative;

  &:hover {
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0.521vw;
      right: 0.521vw;
      background-image: url("../assets/images/zoom-overlay-icon.svg");
      background-position: center;
      background-size: contain;
      width: 1.458vw;
      max-width: 28px;
      height: 1.458vw;
      max-height: 28px;
    }
  }
}

/*date time picker */

.date-time-picker.dp-material.dp-popup {
  border: 0;
  box-shadow: 0px 10px 15px var(--c-date-time-picker-bs);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 450px;
  display: flex;
  flex-direction: row;

  .time-selector {
    padding: 1.48vh 1.25vw;
    border-bottom: 1px solid #dedede;
    display: flex;
    align-items: center;

    .quantity {
      position: relative;
      display: inline-block;
      max-width: 48px;
      max-height: 48px;

      input {
        width: 2.5vw;
        height: 3.70vh !important;
        border-radius: 6px !important;
        border: 1px solid #dedede;
        font-size: var(--fs-12);
        margin: 0;
        padding: 0;
        text-indent: 12px;
        font-family: var(--ff-bold);
        min-width: 42px;
        min-height: 34px;
      }

      .quantity__btn {
        height: 6px;
        position: absolute;
        cursor: pointer;
        text-align: center;
        right: 6%;

        svg-icon {
          display: flex;
        }

        &.quantity__btn--up {
          top: 30% !important;
        }

        &.quantity__btn--down {
          bottom: 30% !important;
        }
      }
    }

    >span {
      padding: 0 6px;
    }

    .merdian-input {
      display: flex;
      align-items: center;
      margin-left: 0.625vw;
      border: 1px solid #dedede;
      border-radius: 6px;
      overflow: hidden;

      label {
        position: relative;
        display: flex;
        cursor: pointer;

        .merdian-radio {
          appearance: none;
          border: unset;
          border-radius: unset;
          cursor: pointer;
          width: 2.55vw;
          height: 3.70vh;
          font-size: var(--fs-12);
          color: #262626;
          text-indent: 12px;
          padding: 0;
          margin: 0;
          min-width: 40px;
          min-height: 32px;

          &.active {
            background-color: var(--c-orange);

            &~p {
              color: var(--c-light);
            }
          }
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin: unset;
          width: 100%;
          height: 100%;
          pointer-events: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }

  .dp-material {
    border: none;

    dp-day-calendar {
      .dp-day-calendar-container {
        padding-bottom: 1.66vh;

        dp-calendar-nav {
          .dp-calendar-nav-container {
            display: flex;
            align-items: center;
            height: 2.96vh;
            border: none;
            margin: 2.59px 2.22px 0;

            .dp-nav-header {
              width: 100%;
              right: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: max(12px, 0.625vw);

              .dp-nav-header-btn {
                font-size: var(--fs-14);
                font-weight: bold;
                line-height: 18px;
                color: var(--c-dark);
                pointer-events: none;

                &:hover {
                  background: none;
                }
              }
            }

            .dp-nav-btns-container {
              position: unset;
              display: flex;
              justify-content: space-between;
              transform: unset;
              right: unset;
              width: 100%;

              .dp-current-location-btn {
                display: none;
              }
            }
          }
        }

        .dp-weekdays {
          margin: 0 1.25vw;
          background-color: var(--c-light-1);

          .dp-calendar-weekday {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1.66vw;
            height: 2.96vh;
            font-size: 11px;
            line-height: 14px;
            color: var(--c-dark);
            min-width: 28px;
            min-height: 28px;
          }
        }

        .dp-calendar-week {
          margin: 0 1.25vw;

          button {
            width: 1.45vw;
            height: 2.59vh;
            font-size: var(--fs-12);
            line-height: 16px;
            color: var(--c-dark);
            font-family: var(--ff-regular);
            margin: 2px;
            min-width: 24px;
            min-height: 24px;

            &:disabled {
              opacity: .5;
              color: var(--c-grey-2);
            }
          }

          .dp-prev-month {
            color: var(--c-grey-2);
          }

          .dp-selected {
            color: var(--c-light);
            background-color: var(--c-orange);
            border-color: var(--c-orange);
          }

          .dp-current-day {
            border: transparent;
          }
        }


        .dp-calendar-wrapper {
          border: none;
        }
      }


    }

    dp-month-calendar {
      .dp-month-calendar-container {
        border-bottom: 1px solid var(--c-grey-5);
        padding-bottom: 18px;

        dp-calendar-nav {
          .dp-calendar-nav-container {
            display: flex;
            align-items: center;
            height: 32px;
            border: none;
            margin: 28px 24px 0px 24px;

            .dp-nav-header {
              width: 100%;
              right: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              .dp-nav-header-btn {
                color: var(--c-dark);
                font-family: var(--ff-bold);
                font-size: var(--fs-14);
                line-height: 18px;

                &:hover {
                  background: none;
                }
              }
            }

            .dp-nav-btns-container {
              position: unset;
              display: flex;
              justify-content: space-between;
              transform: unset;
              right: unset;
              width: 100%;

              .dp-current-location-btn {
                display: none;
              }
            }
          }
        }

        .dp-calendar-wrapper {
          border: none;

          .dp-months-row {
            display: flex;
            justify-content: space-around;
            margin: 0px 24px;

            button {
              width: 32px;
              height: 32px;
              color: var(--c-dark);
              font-family: var(--ff-bold);
              font-size: 13px;
              line-height: 16px;
              margin: 2px;
            }

            .dp-prev-month {
              color: var(--c-gray-2);
            }

            .dp-selected {
              color: var(--c-light);
              background-color: var(--c-orange);
              border-color: var(--c-orange);
            }
          }
        }
      }


    }

    dp-time-select {
      display: none;

      ul {
        li.dp-time-select-control {
          button {
            width: 28px;
            height: 28px;

            &::before {
              top: 0px;
            }
          }

          .dp-time-select-control-down {
            &::before {
              top: -3px;
            }
          }
        }
      }
    }

  }

  .date-time-display {
    text-align: center;
    margin-top: 4px;

    .date-time-display-content {

      span {
        font-family: var(--ff-bold);
        font-size: var(--fs-14);
        line-height: 15px;
      }

      .selected-date {
        color: var(--c-orange);
      }
    }

    .operation-buttons {
      margin: 2.222vh 0vw;
      display: flex;
      justify-content: center;

      >div {
        border-radius: 8px;
        padding: 8px 12px;
        cursor: pointer;

        p {
          font-size: 12px;
          line-height: 21px;
          margin: 0;
        }
      }

      .cancel-cta {
        border: 1px solid var(--c-grey-4);
        margin-right: 1vw;

        p {
          color: #555;
        }
      }

      .save-cta {
        border: 1px solid var(--c-orange);
        background: var(--c-orange);

        p {
          color: #fff;
        }
      }
    }
  }
}

/* Pre tag */
pre {
  overflow: unset;
  white-space: break-spaces
}

.content-height {
  height: 100%;
}