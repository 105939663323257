@font-face {
  font-family: "Proxima Nova W05 Regular";
  src: url("../assets/fonts/5596744/33ca2000-af7b-4444-97cd-6392ab2e24b2.woff2") format("woff2"),
    url("../assets/fonts/5596744/1c003cc6-0f8f-4c82-adde-a5a026b56b50.woff") format("woff");
}


@font-face {
  font-family: "Proxima Nova W01 SemiBold";
  src: url("../assets/fonts/5738932/c7dcb253-8dca-459b-aaa9-afef9131ef22.woff2") format("woff2"),
    url("../assets/fonts/5738932/390be235-bf11-42ee-8ba1-2dd10ca6a4ac.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova W05 Bold";
  src: url("../assets/fonts/5596929/3c35dc60-9fe3-480f-90ac-e453db0271e2.woff2") format("woff2"),
    url("../assets/fonts/5596929/2b2b7939-693b-44cd-873a-1aa065a906b8.woff") format("woff");
}