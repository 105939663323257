@import 'light';
@import 'dark';

:root {
  --ff-regular: "Proxima Nova W05 Regular";
  --ff-bold: "Proxima Nova W05 Bold";
  --ff-semibold: "Proxima Nova W01 Semibold";

  --fs-40: calc(34px + 0.313vw);
  --fs-32: calc(26px + 0.313vw);
  --fs-30: calc(24px + 0.313vw);
  --fs-28: calc(22px + 0.313vw);
  --fs-24: calc(18px + 0.313vw);
  --fs-20: calc(16px + 0.208vw);
  --fs-18: calc(16px + 0.1042vw);
  --fs-16: calc(14px + 0.104vw);
  --fs-14: calc(12px + 0.104vw);
  --fs-12: calc(10px + 0.104vw);

  --lh-40: calc(34px + 0.313vw);
  --lh-32: calc(26px + 0.313vw);
  --lh-30: calc(24px + 0.313vw);
  --lh-28: calc(22px + 0.313vw);
  --lh-24: calc(18px + 0.313vw);
  --lh-20: calc(16px + 0.208vw);
  --lh-18: calc(16px + 0.1042vw);
  --lh-16: calc(14px + 0.104vw);
  --lh-14: calc(12px + 0.104vw);
  --lh-12: calc(10px + 0.104vw);
  --lh-normal: normal;
  --side-bar-width: 5.833vw;
  --header-height: 6vh;
  --footer-height: 10vh
}