/* You can add global styles to this file, and also import other style files */
@import "./partials/reset";
@import "./partials/fonts";
@import "./partials/variables";
@import "./partials/mediaqueries";
@import "./partials/global";
@import "./partials/forms";
@import "./partials/buttons";
@import "./partials/toastr";
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

body {
  font-family: var(--ff-regular);
  background-color: var(--c-body-bg);
}

app-leftnavigation {
  display: flex;
  position: sticky;
  height: 100%;
  background-color: var(--c-body-bg);
  border-right: 1px solid #aaaaaa38;
}

app-content {
  display: inline-block;
  flex: 1;
}

app-file-editor {
  width: 100%;
  flex: 1;
  display: flex;
}


.try-project-container {
  app-file-editor {
    .programming-editor-container {
      // padding: 24px 0px;
      padding: 1.25vw 0px 0px 0px;
      // height: calc(100% - 3.333vw);
      // .editor-scroll,
      // .output-scroll {
      //   height: calc(100vh - 352px);

      //   @media screen and (min-height:701px) and (max-height:800px) {
      //     height: calc(100vh - 322px);
      //   }

      //   @media screen and (max-height:700px) {
      //     height: calc(100vh - 312px);
      //   }
      // }
    }
  }


}

.draggable-window {
  position: fixed;
  justify-items: center;
  min-width: 26.042vw;
  min-height: 46.296296296296vh;
  user-select: none;
  cursor: move;
  z-index: 9999;
  background: var(--c-light);
}

.createNewExercise-modal {
  .modal-dialog {
    display: flex;
    align-items: center;
    width: 52.917vw;
    max-width: 1016px;
    min-width: 800px;
    height: -webkit-fill-available;
  }
}

.navbar-brand[data-theme='light'] {
  border: 10px solid red
}


app-exercise-projects {
  .projectlist-check {
    app-projectlistcard {
      .project-detail {
        margin-bottom: 0px !important;
      }
    }
  }
}

.test-case-modal {
  .modal-content {
    background-color: var(--c-model-content-border) !important;
  }
}

.image-zoom-modal {
  .modal-content {
    .image-zoom-wrapper {
      .scrollbar {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}