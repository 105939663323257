/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu,
ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Proxima Nova W05 Regular";
  src: url("../assets/fonts/5596744/33ca2000-af7b-4444-97cd-6392ab2e24b2.woff2") format("woff2"), url("../assets/fonts/5596744/1c003cc6-0f8f-4c82-adde-a5a026b56b50.woff") format("woff");
}
@font-face {
  font-family: "Proxima Nova W01 SemiBold";
  src: url("../assets/fonts/5738932/c7dcb253-8dca-459b-aaa9-afef9131ef22.woff2") format("woff2"), url("../assets/fonts/5738932/390be235-bf11-42ee-8ba1-2dd10ca6a4ac.woff") format("woff");
}
@font-face {
  font-family: "Proxima Nova W05 Bold";
  src: url("../assets/fonts/5596929/3c35dc60-9fe3-480f-90ac-e453db0271e2.woff2") format("woff2"), url("../assets/fonts/5596929/2b2b7939-693b-44cd-873a-1aa065a906b8.woff") format("woff");
}
:root[data-theme=light] {
  --c-body-bg: #FFF;
  --c-light: #FFF;
  --c-light-1: #F7F8F9;
  --c-light-2: #E4E4E4;
  --c-light-3: #EFF4EF;
  --c-light-4: #EBEEF6;
  --c-light-5: #EBEEF6;
  --c-dark: #3A3932;
  --c-orange: #FF6600;
  --c-orange-2: #FFDDB0;
  --c-orange-3: #FFDDB0;
  --c-red-1: #FF270D;
  --c-red-2: #EA5455;
  --c-red-3: #D00909;
  --c-red-4: #FF3113;
  --c-bg-red: #FFE3E3;
  --c-yellow: #FDB515;
  --c-blue: #3C5A9A;
  --c-bg-blue: #CEDCEC;
  --c-green: #36A952;
  --c-bg-green: #E5EDC3;
  --c-grey-1: #696868;
  --c-gray-2: #AAAAAA;
  --c-grey-3: #E4E4E4;
  --c-grey-4: #555555;
  --c-grey-5: #D1D1D1;
  --c-grey-6: #D8D7D6;
  --c-grey-7: rgba(228, 228, 228, 0.60);
  --c-grey-8: #3A3932;
  --c-dropdown-bg: rgba(228, 228, 228, 0.60);
  --c-sucess: #36A952;
  --c-error: #DB3232;
  --switch-bg: #4AD395;
  --c-text-light: #FFF;
  --c-text-dark: #3A3932;
  --c-btn-primary-color: #FF6600;
  --c-header-box-shadow: rgba(194, 194, 194, 0.20);
  --c-bg-draft: #FFF8EF;
  --c-bg-expired: rgba(228, 228, 228, 0.60);
  --c-bg-ongoing: #E3FFF2;
  --c-bg-accordion-btn: #FFDDB0;
  --c-border: #CEDCEC;
  --c-modal-header-bg: #FFDDB0;
  --c-modal-body-bg: #FFFFFF;
  --c-modal-footer-bg: #FFFFFF;
  --c-btn-secondary-border: #696868;
  --c-textarea-bg: #FFFFFF;
  --c-textarea-border: #CEDCEC;
  --c-model-content-border: #FFFFFF;
  --c-text-color: #3A3932;
  --c-close-icon: #3A3932;
  --c-ngoption: #696868;
  --c-selected: rgba(228, 228, 228, 0.60);
  --c-selectedFolder: rgba(228, 228, 228, 0.80);
  --c-pagetitle: #3A3932;
  --c-table-header: rgba(228, 228, 228, 0.60);
  --c-table-body: #FFFFFF;
  --c-status-unanswered: #F7F8F9;
  --c-status-review: #CEDCEC;
  --c-status-answered: #E3FFF2;
  --c-status-unanswered-text: #AAAAAA;
  --c-status-review-text: #3C5A9A;
  --c-status-answered-text: #4AD395;
  --c-progress-success: #00B144;
  --c-progress-grey: #D8D7D6;
  --c-date-time-picker-bs: #89898927;
  --c-text-color-header: #3A3932;
}

:root[data-theme=dark] {
  --c-body-bg: #1E1E1E;
  --c-light: #1E1E1E;
  --c-light-1: #1E1E1E;
  --c-light-2: #1E1E1E;
  --c-light-3: #4A4A4A;
  --c-light-4: rgba(30, 30, 30, 0.15);
  --c-light-5: #7B8EBC;
  --c-dark: #1E1E1E;
  --c-orange: #FF6600;
  --c-orange-2: #FFDDB0;
  --c-orange-3: #7B8EBC;
  --c-red-1: #FF270D;
  --c-red-2: #EA5455;
  --c-red-3: #D00909;
  --c-red-4: #FF3113;
  --c-bg-red: #FFE3E3;
  --c-yellow: #FDB515;
  --c-blue: #4D8CD7;
  --c-bg-blue: #4A4A4A;
  --c-green: #36A952;
  --c-bg-green: #E5EDC3;
  --c-grey-1: #FFF;
  --c-gray-2: #4A4A4A;
  --c-grey-3: #E4E4E4;
  --c-grey-4: #555555;
  --c-grey-5: #D1D1D1;
  --c-grey-6: #D8D7D6;
  --c-dropdown-bg: #737373;
  --c-sucess: #36A952;
  --c-error: #DB3232;
  --switch-bg: #4AD395;
  --c-text-light: #FFF;
  --c-text-dark: #FCFCFCDE;
  --c-btn-primary-color: #F57824;
  --c-bg-draft: #FFF8EF;
  --c-bg-expired: rgba(228, 228, 228, 0.60);
  --c-bg-ongoing: #E3FFF2;
  --c-bg-accordion-btn: #1E1E1E;
  --c-border: #7B8EBC;
  --c-modal-header-bg: #2E2E2E;
  --c-modal-body-bg: #151515;
  --c-modal-footer-bg: #151515;
  --c-btn-secondary-border: rgba(255, 255, 255, 0.75);
  --c-textarea-bg: #1E1E1E;
  --c-textarea-border: #1E1E1E;
  --c-model-content-border: #1E1E1E;
  --c-text-color: rgba(255, 255, 255, 0.75);
  --c-close-icon: rgba(255, 255, 255, 0.75);
  --c-ngoption: #696868;
  --c-selected: #2E2E2E;
  --c-selectedFolder: #3E3E3E;
  --c-pagetitle: rgba(252, 252, 252, 0.87);
  --c-table-header: #2E2E2E;
  --c-table-body: #151515;
  --c-status-unanswered: rgba(115, 115, 115, 0.60);
  --c-status-review: #324760;
  --c-status-answered: #37624E;
  --c-status-unanswered-text: rgba(255, 255, 255, 0.75);
  --c-status-review-text: rgba(255, 255, 255, 0.75);
  --c-status-answered-text: rgba(255, 255, 255, 0.75);
  --c-text-color-header: #3A3932;
}

:root {
  --ff-regular: "Proxima Nova W05 Regular";
  --ff-bold: "Proxima Nova W05 Bold";
  --ff-semibold: "Proxima Nova W01 Semibold";
  --fs-40: calc(34px + 0.313vw);
  --fs-32: calc(26px + 0.313vw);
  --fs-30: calc(24px + 0.313vw);
  --fs-28: calc(22px + 0.313vw);
  --fs-24: calc(18px + 0.313vw);
  --fs-20: calc(16px + 0.208vw);
  --fs-18: calc(16px + 0.1042vw);
  --fs-16: calc(14px + 0.104vw);
  --fs-14: calc(12px + 0.104vw);
  --fs-12: calc(10px + 0.104vw);
  --lh-40: calc(34px + 0.313vw);
  --lh-32: calc(26px + 0.313vw);
  --lh-30: calc(24px + 0.313vw);
  --lh-28: calc(22px + 0.313vw);
  --lh-24: calc(18px + 0.313vw);
  --lh-20: calc(16px + 0.208vw);
  --lh-18: calc(16px + 0.1042vw);
  --lh-16: calc(14px + 0.104vw);
  --lh-14: calc(12px + 0.104vw);
  --lh-12: calc(10px + 0.104vw);
  --lh-normal: normal;
  --side-bar-width: 5.833vw;
  --header-height: 6vh;
  --footer-height: 10vh ;
}

/* margin */
.m-16 {
  margin: 2.2222222222vh 1.25vw;
}

.mx-16 {
  margin-right: 0.833vw;
  margin-left: 0.833vw;
}

.my-16 {
  margin-top: 1.4814814815vh;
  margin-bottom: 1.4814814815vh;
}

.mt-16 {
  margin-top: 1.4814814815vh;
}

.mr-16 {
  margin-right: 0.833vw;
}

.mb-16 {
  margin-bottom: 1.4814814815vh;
}

.ml-16 {
  margin-left: 0.833vw;
}

.m-24 {
  margin: 2.2222222222vh 1.25vw;
}

.mx-24 {
  margin-right: 1.25vw;
  margin-left: 1.25vw;
}

.my-24 {
  margin-top: 2.2222222222vh;
  margin-bottom: 2.2222222222vh;
}

.mt-24 {
  margin-top: 2.2222222222vh;
}

.mr-24 {
  margin-right: 1.25vw;
}

.mb-24 {
  margin-bottom: 2.2222222222vh;
}

.ml-24 {
  margin-left: 1.25vw !important;
}

.m-32 {
  margin: 2.962962963vh 1.667vw;
}

.mx-32 {
  margin-right: 1.667vw;
  margin-left: 1.667vw;
}

.my-32 {
  margin-top: 2.962962963vh;
  margin-bottom: 2.962962963vh;
}

.mt-32 {
  margin-top: 2.962962963vh;
}

.mr-32 {
  margin-right: 1.667vw;
}

.mb-32 {
  margin-bottom: 2.962962963vh;
}

.ml-32 {
  margin-left: 1.667vw;
}

.mr-48 {
  margin-right: 2.5vw;
}

.ml-48 {
  margin-left: 2.5vw;
}

.p-16 {
  padding: 1.4814814815vh 0.833vw;
}

.px-16 {
  padding-right: 0.833vw;
  padding-left: 0.833vw;
}

.py-16 {
  padding-top: 1.4814814815vh;
  padding-bottom: 1.4814814815vh;
}

.pt-16 {
  padding-top: 1.4814814815vh;
}

.pr-16 {
  padding-right: 0.833vw;
}

.pb-16 {
  padding-bottom: 1.4814814815vh;
}

.pl-16 {
  padding-left: 0.833vw;
}

.p-24 {
  padding: 2.2222222222vh 1.25vw;
}

.px-24 {
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

.py-24 {
  padding-top: 2.2222222222vh;
  padding-bottom: 2.2222222222vh;
}

.pt-24 {
  padding-top: 2.2222222222vh;
}

.pr-24 {
  padding-right: 1.25vw;
}

.pb-24 {
  padding-bottom: 2.2222222222vh;
}

.pl-24 {
  padding-left: 1.25vw;
}

.pt-32 {
  padding-top: 2.962962963vh;
}

.pr-32 {
  padding-right: 1.667vw;
}

.pb-32 {
  padding-bottom: 2.962962963vh;
}

.pl-32 {
  padding-left: 1.667vw;
}

.py-32 {
  padding-top: 2.962962963vh;
  padding-bottom: 2.962962963vh;
}

.py-48 {
  padding-top: 4.4444444444vh;
  padding-bottom: 4.4444444444vh;
}

/* font */
.ff-bold {
  font-family: var(--ff-bold);
}

.ff-semibold {
  font-family: var(--ff-semibold);
}

.fs-12 {
  font-size: var(--fs-12);
}

.fs-14 {
  font-size: var(--fs-14);
}

.fs-16 {
  font-size: var(--fs-16);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-22 {
  font-size: var(--fs-22);
}

.fs-24 {
  font-size: var(--fs-24);
}

.fs-28 {
  font-size: var(--fs-28);
}

.fs-30 {
  font-size: var(--fs-30);
}

.fs-32 {
  font-size: var(--fs-32);
}

.fs-40 {
  font-size: var(--fs-40);
}

/* font */
.lh-16 {
  line-height: var(--lh-16);
}

.cursor-pointer {
  cursor: pointer;
}

/* typography */
.page-title {
  position: relative;
  width: max-content;
  font-size: var(--fs-24);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
  color: var(--c-pagetitle);
  padding-bottom: 4px;
  margin-bottom: 2.2222222222vh;
}
.page-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--c-orange);
  border-radius: 2px;
}

.primary-text {
  color: var(--c-orange);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: 150%;
  padding: 2px 8px;
}
.primary-text:hover {
  border-radius: 100px;
  background-color: var(--c-light-1);
}

.heading-1 {
  color: var(--c-text-dark);
  font-size: var(--fs-24);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}

.heading-2 {
  color: var(--c-text-dark);
  font-size: var(--fs-20);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}

.heading-3 {
  color: var(--c-text-dark);
  font-size: var(--fs-18);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-24);
}

.big-body-text {
  color: var(--c-text-dark);
  font-size: var(--fs-18);
  line-height: normal;
}

.big-body-text-2 {
  color: var(--c-grey-1);
  font-size: var(--fs-18);
  line-height: normal;
}

.big-body-text-3 {
  color: var(--c-gray-2);
  font-size: var(--fs-18);
  line-height: normal;
}

.body-sub-text, .input-group ::placeholder,
.form-group ::placeholder, .input-group ::-webkit-input-placeholder,
.form-group ::-webkit-input-placeholder, .dropdown-menu.show li a {
  color: var(--c-grey-1);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
}

.body-pill-text {
  color: var(--c-text-dark);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-normal);
}

.big-bold-assistive-text {
  color: var(--c-text-dark);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-bold-assistive-text {
  color: var(--c-grey-1);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-bold-assistive-text-orange {
  color: var(--c-orange);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
}

.body-text {
  color: var(--c-grey-1);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-24z);
  word-break: break-word;
}

.text-button {
  color: var(--c-blue);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: var(--lh-24z);
  cursor: pointer;
}

.small-assistive-text {
  color: var(--c-grey-1);
  font-size: var(--fs-12);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
}

/* typography */
/* level */
.level-easy {
  background: var(--c-bg-green);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-green);
}

.level-medium {
  background: var(--c-bg-blue);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-blue);
}

.level-difficult {
  background: var(--c-bg-red);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-red-2);
}

/* status */
.status-draft {
  background: var(--c-bg-draft);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-yellow);
}

.status-yet-to-start {
  background: var(--c-bg-blue);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-blue);
}

.status-expired {
  background: var(--c-bg-expired);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-grey-1);
}

.status-ongoing {
  background: var(--c-bg-ongoing);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-sucess);
}

/* dropdown menu */
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  background: var(--c-light);
  padding: 0px;
}
.dropdown-menu.show li {
  width: 100%;
}
.dropdown-menu.show li a {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.9259259259vh 0.833vw;
}
.dropdown-menu.show li a img {
  margin-right: 8px;
}
.dropdown-menu.show li a:hover, .dropdown-menu.show li a:active {
  background-color: var(--c-dropdown-bg);
}

.dropdown-filter-toggle::after {
  width: 10px;
  height: 10px;
  margin-top: 3px;
  background-image: url("../assets/images/dropdown-open.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
}
.dropdown-filter-toggle:hover::after {
  transform: rotate(180deg);
}

/* nav tabs */
.nav-tabs.create-project-tabs {
  border-bottom: 1px solid var(--c-bg-blue);
}
.nav-tabs.create-project-tabs li .nav-link {
  position: relative;
  color: var(--c-grey-1);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
  border: none;
  padding: 0px 0px 3px 0px;
  margin-right: 2.5vw;
}
.nav-tabs.create-project-tabs li .nav-link:hover {
  border: none;
}
.nav-tabs.create-project-tabs li .nav-link.active {
  color: var(--c-dark);
  font-size: var(--fs-16);
  font-style: normal;
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
  border: none;
}
.nav-tabs.create-project-tabs li .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: -webkit-fill-available;
  height: 2px;
  background-color: var(--c-orange);
  border-radius: 2px;
}

/* ng select */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  display: none;
}

ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--c-gray-2) !important;
  font-size: var(--fs-16);
}

ng-select .ng-select-container .ng-value-container .ng-value {
  color: var(--c-text-dark) !important;
}

.ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label {
  background-color: var(--c-body-bg);
  font-size: var(--fs-14);
  line-height: 24px;
  font-family: "Proxima Nova W05 Regular";
}

.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: nowrap !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select .ng-select-container {
  height: 4.4444444444vh !important;
  min-height: 40px;
  max-height: 48px;
  border-radius: 8px;
  border-color: var(--c-bg-blue) !important;
  background: var(--c-body-bg) !important;
  cursor: pointer !important;
}
.ng-select .ng-select-container:hover {
  box-shadow: none !important;
}

.ng-dropdown-panel {
  border-color: var(--c-bg-blue) !important;
}

.ng-dropdown-panel.ng-select-bottom {
  border-top-color: var(--c-bg-blue) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background: var(--c-body-bg) !important;
  color: var(--c-grey-1) !important;
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  color: var(--c-grey-1) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: var(--c-body-bg) !important;
}

.multi-ng-select .ng-value .ng-value-label::after {
  content: ",";
  font-weight: bolder;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-family: "Proxima Nova W05 Regular";
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: initial !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container div:nth-last-child(2) span.ng-value-label:after {
  display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--c-selected) !important;
  font-family: "Proxima Nova W05 Regular";
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: var(--c-selected) !important;
  color: rgba(85, 85, 85, 0.75);
  font-size: var(--fs-14);
  font-style: normal;
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  color: var(--c-grey-1) !important;
  font-size: var(--fs-14);
  font-style: normal;
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}

.ng-dropdown-panel.ng-select-multiple.ng-select-top .ng-option,
.ng-dropdown-panel.ng-select-multiple.ng-select-top .ng-option.ng-option-selected {
  color: var(--c-grey-1) !important;
  font-size: var(--fs-14);
  font-style: normal;
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}

.custom-clear-all ng-select ng-dropdown-panel .ng-dropdown-panel-items .ng-option:nth-child(1) {
  display: flex !important;
  justify-content: end;
  color: var(--c-orange) !important;
}

.custom-ng-select .ng-select-container {
  min-height: 62px;
  min-width: 200px;
}

.ng-arrow-wrapper {
  background-image: url("../assets/images/dropdown-open.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.ng-arrow-wrapper .ng-arrow {
  border: none !important;
}

.ng-select-opened .ng-arrow-wrapper {
  transform: rotate(180deg);
}

/* sort ng select */
.sort-ng-select {
  min-width: 72px;
  max-height: 36px;
}
.sort-ng-select .ng-select-container {
  cursor: pointer;
  border: none;
}
.sort-ng-select .ng-select-container .ng-clear-wrapper .ng-clear {
  margin-left: 4px;
}
.sort-ng-select .ng-select-container .ng-value-container {
  padding-left: 14px;
}
.sort-ng-select .ng-select-container:hover {
  box-shadow: none;
  background-color: var(--c-light-1) !important;
  border-radius: 0px !important;
}
.sort-ng-select .ng-select-container:hover .ng-value-container .ng-placeholder {
  color: var(--c-orange) !important;
}
.sort-ng-select .ng-select-container:hover .ng-value-container .ng-value .ng-value-label {
  background: var(--c-light-1);
}
.sort-ng-select .ng-has-value::before {
  content: "";
  position: absolute;
  left: 8px;
  top: 10px;
  width: 6px;
  height: 6px;
  background: url("../assets/images/red_Dot.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.sort-ng-select .ng-dropdown-panel {
  border: 1px solid var(--c-bg-blue);
  box-shadow: none;
  border-radius: 4px;
  width: 272px;
}
.sort-ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  border-radius: 4px;
}
.sort-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--c-grey-1);
  font-size: var(--fs-14) !important;
  line-height: normal !important;
  font-family: "Proxima Nova W05 Regular";
}
.sort-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  color: var(--c-grey-1);
  font-size: var(--fs-14) !important;
  line-height: normal !important;
  font-family: "Proxima Nova W05 Regular";
}

/* sort ng select */
/* Ck editor*/
.ck.ck-content {
  padding: 0 1.25vw !important;
}
.ck.ck-content p {
  word-break: break-word;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  background-color: var(--c-light-1);
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--c-bg-blue) !important;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-color: var(--c-bg-blue) !important;
  background-color: var(--c-light-1);
  height: 53.68px !important;
}

/*required*/
.required::after {
  content: "*";
  color: var(--c-red-1);
  padding-left: 4px;
}

.error-message {
  color: var(--c-red-1);
}

/*Footerbtn*/
.footerBtn-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: var(--footer-height);
  padding: 12px 1.667vw 12px 0px;
  align-items: center;
  box-shadow: 0px -4px 6px 0px rgba(58, 57, 50, 0.05);
}

/*Footerbtn*/
.footerBtn {
  position: fixed;
  bottom: 0px;
  width: calc(100vw - var(--side-bar-width));
  height: var(--footer-height);
  background-color: var(--c-light);
  padding: 12px 1.667vw 12px 0px;
  align-items: center;
  right: 0px;
  box-shadow: 0px -4px 6px 0px rgba(58, 57, 50, 0.05);
}

content container .content-container {
  position: relative;
  height: calc(100vh - 72px);
  overflow: hidden;
}

/* Modals */
.test-case-modal .modal-dialog {
  height: 82.962962963vh;
  max-width: 72.917vw;
}

.image-zoom-modal .modal-dialog {
  width: 52.917vw;
  max-width: 1016px;
  overflow: hidden;
}

.modal-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--c-orange-2);
}
.modal-header .close-button {
  border-style: none;
  background-color: var(--c-orange-2);
  max-width: 20px;
  width: 1.042vw;
  max-height: 20px;
  height: 1.8518518519vh;
}
.modal-header .close-button svg-icon {
  max-width: 100%;
  max-height: 100%;
}
.modal-header .close-button svg-icon svg {
  max-width: 100%;
  max-height: 100%;
}
.modal-header .close-icon {
  width: 0.938vw;
  height: 0.938vw;
  max-width: 18px;
  max-height: 18px;
}
.modal-header .close-icon svg-icon svg {
  max-width: 100%;
}

/*accodrion */
.accordion .accordion-item {
  border-color: var(--c-bg-blue);
  background-color: var(--c-light);
}
.accordion .accordion-item .accordian-header .accordion-button {
  padding: 12px;
}
.accordion .accordion-item .accordian-body {
  padding: 16px;
}

.project-player-accordian-button::after {
  display: none;
}

.accordion-button {
  background-color: var(--c-light-1);
  cursor: default !important;
  color: var(--c-text-dark) !important;
}
.accordion-button:hover {
  cursor: default;
}
.accordion-button .accordion-toggle-icon {
  display: block;
  max-width: 16px;
  min-width: 12px;
  width: 0.833vw;
  max-height: 16px;
  min-height: 12px;
  height: 0.833vw;
}

.accordion-button:not(.collapsed) {
  background-color: var(--c-light-1);
  box-shadow: none;
}

.accordion-button .accordion-toggle-icon:not(.collapsed)::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/projectPlayer/collapse_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: inherit;
  max-width: 16px;
  min-width: 12px;
  width: 0.833vw;
  max-height: 16px;
  min-height: 12px;
  height: 0.833vw;
}

.accordion-button .accordion-toggle-icon.collapsed::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/projectPlayer/expand_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: 100%;
  max-width: 16px;
  min-width: 12px;
  width: 0.833vw;
  max-height: 16px;
  min-height: 12px;
  height: 0.833vw;
}

.accordion:focus,
.accordion-button :focus,
.accordion-button:focus,
.accordion-button :focus {
  outline: none !important;
  box-shadow: none !important;
}

.inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
}

.theme-dropdown span::after,
.output-dropdown span::after {
  border: none;
}

.pulse {
  overflow: hidden;
  background: rgba(228, 228, 228, 0.6);
}

.pulse:before {
  content: "";
  display: inline-flex;
  height: 100%;
  width: 100%;
  animation: pulse 1s infinite;
  background: linear-gradient(to right, transparent, #D9D9D9, transparent);
  animation-direction: alternate;
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
/*date time picker */
/* date time picker */
/* filter dropdown */
.filter-dropdown .dropdown-filter-toggle {
  position: relative;
  min-width: 72px;
  height: 36px;
  text-align: center;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-grey-1);
  cursor: pointer;
  font-size: var(--fs-16);
}
.filter-dropdown .dropdown-filter-toggle:hover {
  background-color: var(--c-light-1);
  color: var(--c-orange) !important;
}
.filter-dropdown .dropdown-filter-toggle span {
  position: absolute;
  left: 4px;
  top: 2px;
}
.filter-dropdown .first-level-menu {
  right: 0;
}
.filter-dropdown .dropdown-menu {
  width: 272px;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  background: var(--c-light);
  padding: 0;
}
.filter-dropdown .dropdown-menu li .dropdown-toggle::after {
  border: none;
  background-image: url("../assets/images/dropdown-left-open.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 13px;
  left: 0;
  margin-top: 0px !important;
  margin-left: 8px !important;
}
.filter-dropdown .dropdown-menu li .dropdown-toggle:hover::after {
  transform: rotate(0deg);
}
.filter-dropdown .dropdown-menu li a {
  display: block !important;
  padding: 10px 24px;
  cursor: pointer;
}
.filter-dropdown .dropdown-menu li a:active {
  color: var(--c-grey-1);
  background: rgba(228, 228, 228, 0.6);
}
.filter-dropdown li:hover > ul.dropdown-menu {
  display: block;
}
.filter-dropdown .dropdown-submenu {
  position: relative;
}
.filter-dropdown .dropdown-submenu ul li a {
  padding: 0px !important;
}
.filter-dropdown .dropdown-submenu ul li a:hover {
  background-color: transparent;
}
.filter-dropdown .dropdown-submenu ul li a .filter-select:hover {
  cursor: pointer;
  background: rgba(228, 228, 228, 0.6);
}
.filter-dropdown .dropdown-submenu ul li a .filter-select .form-check-label {
  cursor: pointer;
}
.filter-dropdown .dropdown-submenu > .dropdown-menu {
  top: 0;
  right: 100%;
}

.filter-select {
  position: relative;
  height: 36px;
  min-height: auto;
  padding: 10px 16px 10px 8px;
  margin-bottom: 0px;
}
.filter-select .form-check-input {
  border: none;
  background-color: transparent;
  margin: 2px 8px 0px 0px;
}
.filter-select .form-check-input:checked[type=checkbox] {
  background-image: url("../assets/images/tick.svg");
  background-color: transparent;
  border: none;
}
.filter-select .form-check-input:focus {
  box-shadow: none;
}
.filter-select .form-check-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 100%;
  padding: 10px 16px 0px 28px;
}

.no-padding {
  padding: 0px !important;
}

.total-edit-marks-card {
  background: var(--c-bg-draft);
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 4px;
}
.total-edit-marks-card span {
  font-size: var(--fs-16);
  line-height: var(--lh-24);
}
.total-edit-marks-card span:first-child {
  font-family: "Proxima Nova W05 Bold";
  color: var(--c-dark);
}
.total-edit-marks-card span:last-child {
  font-family: "Proxima Nova W01 SemiBold";
  color: var(--c-orange);
}

.projects-selected {
  background: var(--c-bg-draft);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  padding: 5px 16px;
  min-width: fit-content;
  border-radius: 100px;
  color: var(--c-orange);
}

.pagination .previous-btn {
  color: rgba(85, 85, 85, 0.6);
  font-size: var(--fs-16);
  font-style: normal;
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  cursor: pointer;
}
.pagination .current-btn {
  color: var(--c-text-dark);
  text-align: center;
  font-size: var(--fs-16);
  font-style: normal;
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}
.pagination .current-btn span {
  font-family: "Proxima Nova W05 Regular";
}
.pagination .next-btn {
  color: var(--c-orange);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  cursor: pointer;
}
.pagination .btn-disabled {
  color: rgba(85, 85, 85, 0.6);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W01 SemiBold";
  line-height: normal;
  cursor: none;
}

.new-practice-project-setting-modal .modal-dialog {
  width: 52.917vw;
  max-width: 1016px;
}

section .body-text p {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.action-container ng-select .ng-select-container {
  min-height: 36px !important;
  max-height: 36px !important;
}
.action-container ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--c-ngoption) !important;
}
.action-container ng-select .ng-select-container .ng-arrow-wrapper {
  margin-top: 4px !important;
}

figure {
  max-width: 300px;
  cursor: pointer;
}
figure img {
  max-width: 100%;
  height: auto;
}

.clear-button {
  cursor: pointer;
  position: absolute;
  display: block;
  right: 50px;
  top: 9px;
  width: 13px;
  height: 12px;
  z-index: 9;
}
.clear-button svg-icon {
  max-width: 100%;
  max-height: 100%;
}
.clear-button svg-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.inputField {
  padding-right: 24px;
}

.multi-ng-select .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.multi-ng-select .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected {
  background-color: var(--c-light) !important;
}
.multi-ng-select .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: var(--c-selected) !important;
}
.multi-ng-select .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-child {
  padding-left: 12px !important;
}
.multi-ng-select .custom-checkbox {
  appearance: none;
}
.multi-ng-select .custom-checkbox::after {
  content: "";
  display: inline-block;
  width: 10px;
  margin-right: 4px;
}
.multi-ng-select .custom-checkbox:checked::after {
  content: "";
  background: url("../assets/images/ngdropdown-selected.svg");
  width: 10px;
  height: 8px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  color: var(--c-orange);
}

.cdk-overlay-container {
  z-index: 1056 !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

figure.image.on {
  position: relative;
}
figure.image.on:hover {
  cursor: pointer;
}
figure.image.on:hover::before {
  content: "";
  position: absolute;
  top: 0.521vw;
  right: 0.521vw;
  background-image: url("../assets/images/zoom-overlay-icon.svg");
  background-position: center;
  background-size: contain;
  width: 1.458vw;
  max-width: 28px;
  height: 1.458vw;
  max-height: 28px;
}

/*date time picker */
.date-time-picker.dp-material.dp-popup {
  border: 0;
  box-shadow: 0px 10px 15px var(--c-date-time-picker-bs);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  min-width: 450px;
  display: flex;
  flex-direction: row;
}
.date-time-picker.dp-material.dp-popup .time-selector {
  padding: 1.48vh 1.25vw;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity {
  position: relative;
  display: inline-block;
  max-width: 48px;
  max-height: 48px;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity input {
  width: 2.5vw;
  height: 3.7vh !important;
  border-radius: 6px !important;
  border: 1px solid #dedede;
  font-size: var(--fs-12);
  margin: 0;
  padding: 0;
  text-indent: 12px;
  font-family: var(--ff-bold);
  min-width: 42px;
  min-height: 34px;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity .quantity__btn {
  height: 6px;
  position: absolute;
  cursor: pointer;
  text-align: center;
  right: 6%;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity .quantity__btn svg-icon {
  display: flex;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity .quantity__btn.quantity__btn--up {
  top: 30% !important;
}
.date-time-picker.dp-material.dp-popup .time-selector .quantity .quantity__btn.quantity__btn--down {
  bottom: 30% !important;
}
.date-time-picker.dp-material.dp-popup .time-selector > span {
  padding: 0 6px;
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input {
  display: flex;
  align-items: center;
  margin-left: 0.625vw;
  border: 1px solid #dedede;
  border-radius: 6px;
  overflow: hidden;
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input label {
  position: relative;
  display: flex;
  cursor: pointer;
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input label .merdian-radio {
  appearance: none;
  border: unset;
  border-radius: unset;
  cursor: pointer;
  width: 2.55vw;
  height: 3.7vh;
  font-size: var(--fs-12);
  color: #262626;
  text-indent: 12px;
  padding: 0;
  margin: 0;
  min-width: 40px;
  min-height: 32px;
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input label .merdian-radio.active {
  background-color: var(--c-orange);
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input label .merdian-radio.active ~ p {
  color: var(--c-light);
}
.date-time-picker.dp-material.dp-popup .time-selector .merdian-input label p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: unset;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.date-time-picker.dp-material.dp-popup .dp-material {
  border: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container {
  padding-bottom: 1.66vh;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container {
  display: flex;
  align-items: center;
  height: 2.96vh;
  border: none;
  margin: 2.59px 2.22px 0;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header {
  width: 100%;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: max(12px, 0.625vw);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn {
  font-size: var(--fs-14);
  font-weight: bold;
  line-height: 18px;
  color: var(--c-dark);
  pointer-events: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn:hover {
  background: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-btns-container {
  position: unset;
  display: flex;
  justify-content: space-between;
  transform: unset;
  right: unset;
  width: 100%;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-btns-container .dp-current-location-btn {
  display: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-weekdays {
  margin: 0 1.25vw;
  background-color: var(--c-light-1);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-weekdays .dp-calendar-weekday {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.66vw;
  height: 2.96vh;
  font-size: 11px;
  line-height: 14px;
  color: var(--c-dark);
  min-width: 28px;
  min-height: 28px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week {
  margin: 0 1.25vw;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week button {
  width: 1.45vw;
  height: 2.59vh;
  font-size: var(--fs-12);
  line-height: 16px;
  color: var(--c-dark);
  font-family: var(--ff-regular);
  margin: 2px;
  min-width: 24px;
  min-height: 24px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week button:disabled {
  opacity: 0.5;
  color: var(--c-grey-2);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week .dp-prev-month {
  color: var(--c-grey-2);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week .dp-selected {
  color: var(--c-light);
  background-color: var(--c-orange);
  border-color: var(--c-orange);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-week .dp-current-day {
  border: transparent;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-day-calendar .dp-day-calendar-container .dp-calendar-wrapper {
  border: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container {
  border-bottom: 1px solid var(--c-grey-5);
  padding-bottom: 18px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container {
  display: flex;
  align-items: center;
  height: 32px;
  border: none;
  margin: 28px 24px 0px 24px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header {
  width: 100%;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn {
  color: var(--c-dark);
  font-family: var(--ff-bold);
  font-size: var(--fs-14);
  line-height: 18px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-header .dp-nav-header-btn:hover {
  background: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-btns-container {
  position: unset;
  display: flex;
  justify-content: space-between;
  transform: unset;
  right: unset;
  width: 100%;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container dp-calendar-nav .dp-calendar-nav-container .dp-nav-btns-container .dp-current-location-btn {
  display: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container .dp-calendar-wrapper {
  border: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container .dp-calendar-wrapper .dp-months-row {
  display: flex;
  justify-content: space-around;
  margin: 0px 24px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container .dp-calendar-wrapper .dp-months-row button {
  width: 32px;
  height: 32px;
  color: var(--c-dark);
  font-family: var(--ff-bold);
  font-size: 13px;
  line-height: 16px;
  margin: 2px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container .dp-calendar-wrapper .dp-months-row .dp-prev-month {
  color: var(--c-gray-2);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-month-calendar .dp-month-calendar-container .dp-calendar-wrapper .dp-months-row .dp-selected {
  color: var(--c-light);
  background-color: var(--c-orange);
  border-color: var(--c-orange);
}
.date-time-picker.dp-material.dp-popup .dp-material dp-time-select {
  display: none;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-time-select ul li.dp-time-select-control button {
  width: 28px;
  height: 28px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-time-select ul li.dp-time-select-control button::before {
  top: 0px;
}
.date-time-picker.dp-material.dp-popup .dp-material dp-time-select ul li.dp-time-select-control .dp-time-select-control-down::before {
  top: -3px;
}
.date-time-picker.dp-material.dp-popup .date-time-display {
  text-align: center;
  margin-top: 4px;
}
.date-time-picker.dp-material.dp-popup .date-time-display .date-time-display-content span {
  font-family: var(--ff-bold);
  font-size: var(--fs-14);
  line-height: 15px;
}
.date-time-picker.dp-material.dp-popup .date-time-display .date-time-display-content .selected-date {
  color: var(--c-orange);
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons {
  margin: 2.222vh 0vw;
  display: flex;
  justify-content: center;
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons > div {
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons > div p {
  font-size: 12px;
  line-height: 21px;
  margin: 0;
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons .cancel-cta {
  border: 1px solid var(--c-grey-4);
  margin-right: 1vw;
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons .cancel-cta p {
  color: #555;
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons .save-cta {
  border: 1px solid var(--c-orange);
  background: var(--c-orange);
}
.date-time-picker.dp-material.dp-popup .date-time-display .operation-buttons .save-cta p {
  color: #fff;
}

/* Pre tag */
pre {
  overflow: unset;
  white-space: break-spaces;
}

.content-height {
  height: 100%;
}

/* checkbox */
.form-check.form-checkbox-orange .form-check-input {
  border-color: var(--c-gray-2);
}
.form-check.form-checkbox-orange .form-check-input:checked {
  color: var(--c-light);
  border-color: var(--c-orange);
  background-color: var(--c-orange);
}
.form-check.form-checkbox-orange .form-check-input:checked:focus {
  border-color: var(--c-orange);
}
.form-check.form-checkbox-orange .form-check-input:focus {
  border-color: var(--c-gray-2);
  box-shadow: none;
}

/* switch */
.form-check.form-switch.form-switch-green .form-check-input {
  background-color: var(--c-gray-2) !important;
  background-image: url("../assets/images/switch-circle.svg") !important;
  border: none;
}
.form-check.form-switch.form-switch-green .form-check-input:checked {
  background-color: var(--switch-bg) !important;
  border-color: var(--switch-bg) !important;
}
.form-check.form-switch.form-switch-green .form-check-input:focus {
  background-image: url("../assets/images/switch-circle.svg") !important;
  border: none;
  box-shadow: none;
}

/* form group */
.form-group {
  margin-bottom: 2.2222222222vh;
}
.form-group .form-label {
  color: var(--c-dark);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: var(--lh-normal);
  margin-bottom: 1.4814814815vh;
}
.form-group .form-control {
  height: 4.4444444444vh;
  min-height: 40px;
  max-height: 48px;
  padding: 0.9259259259vh 0.521vw;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  color: var(--c-dark);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}
.form-group .form-control:focus {
  box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: var(--c-light-2);
  opacity: 0.6;
}

/*search box */
.search-field {
  width: 20.938vw;
  max-width: 402px;
  max-height: 46px;
  min-height: 40px;
}
.search-field input {
  border-right-width: 0px;
  border-color: var(--c-bg-blue);
}
.search-field input:focus {
  border-right-width: 0px;
  border-color: var(--c-bg-blue);
  box-shadow: none;
}
.search-field .search-btn {
  display: flex;
  align-items: center;
  max-width: 52px;
  background-color: var(--c-orange);
}
.search-field .search-btn:active {
  background-color: var(--c-orange);
  border-color: var(--c-orange);
}

/* placeholder */
.input-group ::-webkit-input-placeholder,
.form-group ::-webkit-input-placeholder {
  color: var(--c-gray-2);
}
.input-group :-ms-input-placeholder,
.form-group :-ms-input-placeholder {
  color: var(--c-gray-2);
  font-size: var(--fs-16);
  font-style: normal;
  font-family: "Proxima Nova W05 Regular";
  line-height: var(--lh-normal);
}
.input-group ::placeholder,
.form-group ::placeholder {
  color: var(--c-gray-2);
}

/* input type number  */
.input-no-sm {
  display: flex;
  width: 4.167vw;
  max-width: 80px;
  height: 4.4444444444vh;
  max-height: 48px;
  min-height: 36px;
  padding: 0.9259259259vh 0.521vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--c-bg-blue);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  color: var(--c-gray-1);
  -moz-appearance: textfield;
}
.input-no-sm:focus-visible {
  outline: none;
}
.input-no-sm:focus {
  border: 1px solid var(--c-bg-blue);
  box-shadow: none;
}
.input-no-sm::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-no-sm::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* custom radio button css */
.custom-radio {
  font-size: var(--fs-16);
  color: var(--c-text-color);
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.custom-radio input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--c-light);
  margin: 0;
  color: var(--c-orange);
  max-width: 24px;
  max-height: 24px;
  width: 1.25vw;
  height: 1.25vw;
  border: 1px solid var(--c-bg-blue);
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.custom-radio input[type=radio]::before {
  content: "";
  height: 0.833vw;
  width: 0.833vw;
  max-width: 16px;
  max-height: 16px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--c-orange);
}
.custom-radio input[type=radio]:focus {
  box-shadow: none;
}
.custom-radio input[type=radio]:checked {
  border: 1px solid var(--c-orange);
}
.custom-radio input[type=radio]:checked::before {
  transform: scale(1);
}

.custom-radio + .custom-radio {
  margin-top: 1em;
}

/* custom radio button css */
.uploading-sql-file-draggable {
  min-width: 641px;
  height: 241px;
  min-height: 241px !important;
}

.file-upload-wrapper {
  position: relative;
}
.file-upload-wrapper svg-icon {
  width: 18px;
  height: 20px;
  display: inline-block;
}
.file-upload-wrapper svg-icon svg {
  max-width: 100%;
}
.file-upload-wrapper .file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-uploadng-wrapper svg-icon {
  width: 18px;
  height: 20px;
  display: inline-block;
}
.file-uploadng-wrapper svg-icon svg {
  max-width: 100%;
}
.file-uploadng-wrapper span {
  color: var(--c-gray-2) !important;
}

.uploading-sql-file-modal {
  width: 641px;
  height: 241px;
  gap: 0px;
  opacity: 0px;
  border-radius: 8px;
  border: 1px solid var(--c-status-review);
}
.uploading-sql-file-modal .uploading-header {
  border-bottom: 1px solid var(--c-status-review);
}
.uploading-sql-file-modal .uploading-body {
  padding: 48px 74px;
}
.uploading-sql-file-modal .uploading-body .col-md-4 {
  flex-basis: 150px;
}
.uploading-sql-file-modal .uploading-body .col-md-8 {
  flex: 1;
}
.uploading-sql-file-modal .uploading-body .progress {
  width: 276px;
  position: relative;
  overflow: visible;
  height: 8px;
  background-color: var(--c-progress-grey) !important;
}
.uploading-sql-file-modal .uploading-body .progress .progress-bar span {
  position: absolute;
  right: -26px;
  color: var(--c-grey-4);
  font-size: var(--fs-12);
}
.uploading-sql-file-modal .uploading-body .progress .bg-success {
  background-color: var(--c-progress-success) !important;
  border-radius: 100px !important;
}
.uploading-sql-file-modal .uploading-body .delete-icon-wrapper {
  margin-left: 32px;
}

.btn-primary, .btn-primary-md {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W05 Bold";
  --bs-btn-font-family: var(--app-font-family-bold);
  --bs-btn-color: var(--c-text-light);
  --bs-btn-bg: var(--c-btn-primary-color);
  --bs-btn-border-color: var(--c-btn-primary-color);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-text-light);
  --bs-btn-hover-bg: var(--c-red-1);
  --bs-btn-hover-border-color: var(--c-btn-primary-color);
  --bs-btn-active-color: var(--c-text-light);
  --bs-btn-active-bg: var(--c-btn-primary-color);
  --bs-btn-active-border-color: var(--c-btn-primary-color);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.btn-primary-md {
  width: 6.979vw;
  min-width: max-content;
  max-width: 134px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.25vw;
  font-size: var(--fs-16);
}

.btn-secondary-orange, .btn-secondary-orange-md {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  height: 5.5555555556vh;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W05 Bold";
  --bs-btn-color: var(--c-orange);
  --bs-btn-bg: var(--c-light);
  --bs-btn-border-color: var(--c-orange);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-text-light);
  --bs-btn-hover-bg: var(--c-red-1);
  --bs-btn-hover-border-color: var(--c-orange);
  --bs-btn-active-color: var(--c-light);
  --bs-btn-active-bg: var(--c-btn-primary-color);
  --bs-btn-active-border-color: var(--c-orange);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.btn-secondary-orange-md {
  min-width: max-content;
  width: 8.125vw;
  max-width: 156px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.25vw;
  font-size: var(--fs-16);
  white-space: nowrap;
}

.btn-secondary-grey, .btn-secondary-grey-md {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  height: 5.5555555556vh;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W01 SemiBold";
  --bs-btn-color: var(--c-grey-1);
  --bs-btn-bg: var(--c-light);
  --bs-btn-border-color: var(--c-grey-1);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-grey-1);
  --bs-btn-hover-bg: var(--c-light-1);
  --bs-btn-hover-border-color: var(--c-grey-1);
  --bs-btn-active-color: var(--c-grey-1);
  --bs-btn-active-bg: var(--c-light-1);
  --bs-btn-active-border-color: var(--c-grey-1);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.btn-secondary-grey-md {
  width: 8.125vw;
  max-width: 156px;
  min-height: 32px;
  max-height: 40px;
  padding: 0.521vw 1.25vw;
  font-size: var(--fs-16);
}

.btn-warning {
  display: flex;
  width: 11.458vw;
  min-width: fit-content;
  max-width: 220px;
  min-height: 44px;
  max-height: 60px;
  padding: 0.521vw 1.667vw;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-20);
  line-height: normal;
  font-family: "Proxima Nova W01 SemiBold";
  --bs-btn-color: var(--c-light);
  --bs-btn-bg: var(--c-red-1);
  --bs-btn-border-color: var(--c-red-1);
  --bs-btn-border-radius: .25rem;
  --bs-btn-hover-color: var(--c-light);
  --bs-btn-hover-bg: var(--c-red-3);
  --bs-btn-hover-border-color: var(--c-red-3);
  --bs-btn-active-color: var(--c-light);
  --bs-btn-active-bg: var(--c-red-3);
  --bs-btn-active-border-color: var(--c-red-3);
  --bs-btn-disabled-color: var(--c-gray-2);
  --bs-btn-disabled-bg: var(--c-light-1);
  --bs-btn-disabled-border-color: var(--c-light-2);
}

.flat-btn {
  display: inline-flex;
  min-height: 28px;
  max-height: 32px;
  height: 2.962962963vh;
  padding: 0 0.625vw;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--c-light-1);
  background: var(--c-light);
  box-shadow: -4px 5px 20px 0px var(--c-light-4);
  color: var(--c-text-dark);
  font-size: var(--fs-14);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
  cursor: pointer;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

.toast-container .ngx-toastr {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25vw !important;
  max-width: 480px;
  min-height: 6.25vw;
  padding: 1.25vw 1.25vw 1.25vw 4.375vw;
  box-shadow: none;
  border-radius: 8px;
  background-position: 1.667vw center !important;
  background-repeat: no-repeat !important;
  background-size: 1.875vw !important;
  pointer-events: none;
}
.toast-container .ngx-toastr:hover {
  box-shadow: none;
}
.toast-container .ngx-toastr .toast-close-button {
  position: absolute;
  top: 1.25vw;
  right: 1.25vw;
  width: 12px;
  height: 12px;
  pointer-events: auto;
}
.toast-container .ngx-toastr .toast-close-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-image: url("../assets/images/toastr/toastr-close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.toast-container .ngx-toastr .toast-close-button:hover, .toast-container .ngx-toastr .toast-close-button:focus {
  opacity: 1;
}
.toast-container .ngx-toastr .toast-close-button span {
  visibility: hidden;
}
.toast-container .ngx-toastr .toast-title {
  color: var(--c-text-light);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Bold";
  line-height: normal;
}
.toast-container .ngx-toastr .toast-message {
  color: var(--c-text-light);
  font-size: var(--fs-16);
  font-family: "Proxima Nova W05 Regular";
  line-height: normal;
}
.toast-container .toast-success {
  background-image: url("../assets/images/toastr/toastr-success.svg") !important;
  background: var(--c-sucess);
}
.toast-container .toast-error {
  background-image: url("../assets/images/toastr/toastr-error.svg") !important;
  background: #DB3232;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
html,
body {
  height: 100%;
}

body {
  font-family: var(--ff-regular);
  background-color: var(--c-body-bg);
}

app-leftnavigation {
  display: flex;
  position: sticky;
  height: 100%;
  background-color: var(--c-body-bg);
  border-right: 1px solid rgba(170, 170, 170, 0.2196078431);
}

app-content {
  display: inline-block;
  flex: 1;
}

app-file-editor {
  width: 100%;
  flex: 1;
  display: flex;
}

.try-project-container app-file-editor .programming-editor-container {
  padding: 1.25vw 0px 0px 0px;
}

.draggable-window {
  position: fixed;
  justify-items: center;
  min-width: 26.042vw;
  min-height: 46.2962962963vh;
  user-select: none;
  cursor: move;
  z-index: 9999;
  background: var(--c-light);
}

.createNewExercise-modal .modal-dialog {
  display: flex;
  align-items: center;
  width: 52.917vw;
  max-width: 1016px;
  min-width: 800px;
  height: -webkit-fill-available;
}

.navbar-brand[data-theme=light] {
  border: 10px solid red;
}

app-exercise-projects .projectlist-check app-projectlistcard .project-detail {
  margin-bottom: 0px !important;
}

.test-case-modal .modal-content {
  background-color: var(--c-model-content-border) !important;
}

.image-zoom-modal .modal-content .image-zoom-wrapper .scrollbar {
  max-width: 100%;
  max-height: 100%;
}